import { Badge, ButtonOverride } from '../api/arena-data';
import { buttonBackgroundCalculation } from '@/uikit/helper_funcs/buttonBackgroundCalculation';
import { ALPHABETICAL_SORT_LABEL } from './constants';
import { ShortGameModelFromFeed } from '@/root/app/[locale]/games/[slug]/types';
import { TCarousel } from '@/features/carousel/model';

export const getBrowserCookie = (name: string): string | undefined => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()?.split(';').shift();
};

export function capitalizeFirstLetter(value: string) {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export function buildButtonCSSOverrides(isDesktopDetected: boolean, overrides?: ButtonOverride[] | null) {
	const result: { [key: string]: string } = {};

	if (!overrides) {
		return result;
	}

	for (const override of overrides ?? []) {
		if (!isDesktopDetected && override.state === 'hover') {
			return {};
		}

		if (override.background) {
			const { bcgNormal } = buttonBackgroundCalculation({
				override_button_normal_background: override.background,
				override_button_normal_background_end: override.background_end,
			});
			result[`--${override.state}-background`] = bcgNormal;
		}
		if (override.border) {
			result[`--${override.state}-border`] = override.border;
		}
		if (override.color) {
			result[`--${override.state}-color`] = override.color;
		}
	}
	return result;
}

// Function to get badges for a specific game by slug
export function getValidBadgesBySlug(dataSrc: any[], slug: string, validBadges: Badge[]) {
	// Find the game in the array by its slug
	const game = dataSrc.find((game) => game.slug === slug);

	if (game?.meta?.badges) {
		const firstMatchingBadge = game.meta.badges.find((badgeLabel: string) => {
			return validBadges.some((badge) => badge.label.toLowerCase() === badgeLabel.toLowerCase());
		});

		// If a matching valid badge is found, return an object with label, bgColor and labelColor
		if (firstMatchingBadge) {
			const validBadge = validBadges.find(
				(badge) => badge.label.toLowerCase() === firstMatchingBadge.toLowerCase(),
			);
			if (validBadge) {
				return {
					label: validBadge.label,
					backgroundColor: validBadge.backgroundColor,
					labelColor: validBadge.labelColor,
				};
			}
		}
	}

	// If the game or its badges are not found, return an object with default values
	return { label: '', backgroundColor: '', labelColor: '' };
}

export function getGameAlias(game: any, gameFeedData: any): string | undefined {
	if (game) {
		const alias = gameFeedData?.find((feedGame: any) => feedGame.slug === game.slug)?.meta?.alias;
		return alias;
	} else {
		return undefined;
	}
}

const isFirefox = () => {
	return navigator.userAgent.indexOf('Firefox') > -1;
};

export async function browserTrackingConsent() {
	// disable tracking for Firefox
	// navigator.doNotTrack (although deprecated on mdn) works for most browsers except Safari
	// safariAllowsTracking dedicated solution for Safari
	return (
		typeof window !== 'undefined' && !isFirefox() && navigator.doNotTrack !== '1' && (await safariAllowsTracking())
	);
}

// Safari doesn't have a browser API to check if cross site cookies are blocked, this method tests if they are blocked using an iframe
export function safariAllowsTracking(): Promise<boolean> {
	if (typeof window === 'undefined') return Promise.resolve(false);

	return new Promise((resolve) => {
		const frame = document.createElement('iframe') as any;
		frame.src = 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/evo-cookie-consent/index.html';
		frame.sandbox = 'allow-scripts allow-same-origin';
		frame.style.display = 'none';
		frame.onload = (e: any) => {
			frame.contentWindow.postMessage(JSON.stringify({ test: 'cookie' }), '*');
		};

		const timeout = setTimeout(() => {
			window.removeEventListener('message', messageHandler);
			document.body.removeChild(frame);
			// in case of timeout we assume consent was not given since that's default on Safari
			resolve(false);
		}, 2000);

		const messageHandler = (event: any) => {
			let data;
			try {
				data = JSON.parse(event.data);
			} catch (e) {
				return;
			}
			if (data['evoCheckCookiesEnable'] === undefined) return;
			window.removeEventListener('message', messageHandler);
			document.body.removeChild(frame);
			timeout && clearTimeout(timeout);
			resolve(data['evoCheckCookiesEnable'] as boolean);
		};

		window.addEventListener('message', messageHandler);
		document.body.appendChild(frame);
	});
}

export function sortFeed(by: string, feed: ShortGameModelFromFeed[]) {
	let sortedFeed = [];
	if (by === ALPHABETICAL_SORT_LABEL) {
		sortedFeed = feed.sort((a, b) => a.name.localeCompare(b.name));
	}
	// Sort by badge
	else {
		sortedFeed = feed.sort((a, b) => {
			const badgeExistsA = a?.meta?.badges?.includes(by);
			const badgeExistsB = b?.meta?.badges?.includes(by);
			if (badgeExistsA && !badgeExistsB) {
				return -1;
			}
			if (!badgeExistsA && badgeExistsB) {
				return 1;
			}
			return a.name.localeCompare(b.name);
		});
	}
	return sortedFeed;
}

export function createSortOptions(sort: string, feed: ShortGameModelFromFeed[]) {
	// Get unique badges from feed
	const uniqueBadges = new Set(feed.flatMap((game) => game?.meta?.badges || []));

	// Remove sort from set if it exists (to avoid duplication)
	uniqueBadges.delete(sort);

	// Create final array with sort as first item
	return [
		sort,
		...(sort !== ALPHABETICAL_SORT_LABEL ? [ALPHABETICAL_SORT_LABEL] : []),
		...Array.from(uniqueBadges).sort(),
	];
}

export function addGameToCarouselCards(carousels: TCarousel[], feed: ShortGameModelFromFeed[]): TCarousel[] {
	return (
		carousels?.map((c: TCarousel) => ({
			...c,
			cards: c.cards.map((card: any) => {
				const feedGame = feed.find((g) => g.slug === card.gameSlug) ?? null;
				if (!feedGame) {
					return card;
				}
				const {
					slug,
					meta: {
						description,
						details,
						name,
						thumbs: {
							graphic_184x400,
							graphic_288x192,
							graphic_56x56,
							graphic_592x400,
							icon_graphic_112x112,
						},
					},
				} = feedGame;
				return {
					...card,
					game: {
						description,
						graphic_184x400,
						graphic_288x192,
						graphic_56x56,
						graphic_592x400,
						icon_graphic_112x112,
						name,
						shortDescription: details,
						slug,
					},
				};
			}),
		})) ?? ([] as TCarousel[])
	);
}
